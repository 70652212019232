<template>
  <s-app-layout
    :title="translate('App.Title')"
    :toolbar-title="toolbar.title"
    :user-profile="userProfile"
    :show-notifications="false"
    :src-image="appLogo"
    :language="appLanguage"
    :sections="sections"
    :with-navigation-sidebar="!isMobile"
    @click:logout="logout"
    @click:home="handleGoHome"
    @click:current-language="onLanguageSelected"
    @click:navigation-item="handleNavigationChanged"
  >
    <template v-if="toolbar.breadcrumb.isEnable" #toolbar-left-content>
      <s-breadcrumb :items="toolbar.breadcrumb.items" />
    </template>
    <router-view />
  </s-app-layout>
</template>

<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'
import { type UserProfile, useAuthentication } from 'sima-suite-authentication'
import appLogo from '@/assets/applogo.svg'
import {
  setSimaSuiteComponentsLanguage,
  SAppLayout,
  type SNavigationDrawerSectionItem,
  SBreadcrumb,
  isMobile
} from 'sima-suite-components-vue3'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/store'
import { computed, onBeforeMount } from 'vue'
import { useBreadcrumb } from '@/composables'
import { useProductFruit } from '@/composables/useProductFruit'
import { productFruits } from 'product-fruits'
import { appLanguage, type Languages } from '@/views/AppView/Languages.ts'

const { toolbar } = useBreadcrumb()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const { key } = useProductFruit()

onBeforeMount(async () => {
  if (key.value) {
    await userStore.getUserProfile()
    productFruits.init(key.value, language.value, {
      username: userStore.user.name,
      email: userStore.user.email
    })
  }
})

const handleGoHome = () => {
  router.push('/')
}

const { translate, language, setLanguage } = useTranslator()
const { getUserProfile, logout } = useAuthentication()

const userProfile = computed(() => {
  const user = getUserProfile() as UserProfile
  return {
    name: user.name as string,
    email: user.email as string,
    picture: user.picture as string
  }
})

const onLanguageSelected = (selectedLanguage: { key: string; value: string }) => {
  const language = selectedLanguage as Languages
  setLanguage(selectedLanguage.key)
  setSimaSuiteComponentsLanguage(language.key)
}

const sections = computed(() => {
  return [
    {
      title: translate('App.Menu.Section.Title.Control').toUpperCase(),
      items: [
        {
          label: translate('App.Menu.Section.Dashboard'),
          icon: 'mdi-sign-direction',
          route: '/',
          name: 'home',
          active: '/' === route.path
        },
        {
          label: translate('App.Menu.Section.LeadList'),
          icon: 'mdi-format-list-checks',
          route: '/leads',
          name: 'leadList',
          active: '/leads' === route.path || route.path.includes('/lead/')
        },
        {
          label: translate('App.Menu.Section.Reports'),
          icon: 'mdi-chart-timeline-variant',
          route: '/reports',
          name: 'reports',
          active: '/reports' === route.path || route.path.includes('/reports/')
        }
      ]
    }
  ]
})

const handleNavigationChanged = (item: SNavigationDrawerSectionItem) => {
  // setActiveSection(item.route!)
  router.push(item.route!)
}
</script>
